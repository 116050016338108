<template>
  <div class="OA displayF flex-directionC">
    <div class="left">
      <div class="title">项目介绍：</div>
      <div class="content">
        闪职网，是集人才信息服务、人才输送服务、员工薪资发放等业务功能为一体的招聘求职服务管理平台。基于海量的互联网人才储备，线上精准匹配人才信息，助力企业完成高效招聘，并配以平台发薪功能，实现人力供应链协同管理体系。
        </br></br>拥有海量的智能人才库资源，灵活搜索人才、智能分类、科学管理等；人才岗位精准匹配，多场景人岗交叉匹配，双向多路人岗匹配，支持自定义预选范围限定，实现更灵活的人才推荐。全方位人才动态监测，优质人才动态提醒、智能人才意向检测。人才生命周期管理，支持怕盘活简历并便捷重新进入招聘流程，支持历史多版本人才履历记录与展示。
      </div>
    </div>
    <div class="right displayF justify-contentC align-itemsC">
      <img src="../../assets/OA2.png" alt="" class="image">
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.OA {
  width: 100%;
  height: 100%;
  background: url("../../assets/OA1.png");
  background-size: 100% 100%;
  padding: 5rem 25rem 5rem 5rem;
  .left {
    .title {
      font-size: 1.25rem;
      color: #000000;
    }
    .content {
      margin: 1.25rem 5rem;
    }
  }
  .right {
    flex: 1;
    height: 100%;
    .image {
      width: 43.75rem;
      height: 26.25rem;
    }
  }
}
</style>